<template>
  <div>
    <div class="mb-4">
      <v-btn-toggle v-model="tab">
        <v-btn class="text-none" flat :disabled="tab === 0">{{ $t("dashboard") }}</v-btn>
        <v-btn class="text-none" flat :disabled="tab === 1">{{ $t("all-orders") }}</v-btn>
      </v-btn-toggle>
    </div>
    <div v-if="tab === 0">
      <div class="mb-4 title">{{ $t("overall-statistics") }}</div>
      <v-layout row wrap>
        <v-flex xs6>
          <v-layout row wrap>
            <v-flex xs6>
              <div class="mb-2 title theme-accent">{{ transactionsCount }}</div>
              <div class="grey--text">{{ $t("transactions") }}</div>
            </v-flex>
            <v-flex xs6>
              <div class="mb-2 title theme-accent">{{ totalAmount }} {{ currency }}</div>
              <div class="grey--text">{{ $t("total-amount") }}</div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3 class="pr-1">
          <date-picker
            v-model="range.start_date"
            type="date"
            :first-day-of-week="1"
            :format="'MMMM DD, YYYY'"
            :editable="false"
            :placeholder="$t('all-time')"
            width="100%"
            :lang="datePickerLang"
          ></date-picker>
        </v-flex>
        <v-flex xs3 class="pl-1">
          <date-picker
            v-model="range.end_date"
            type="date"
            :first-day-of-week="1"
            :format="'MMMM DD, YYYY'"
            :not-before="range.start_date"
            :editable="false"
            :placeholder="$t('all-time')"
            width="100%"
            :lang="datePickerLang"
          ></date-picker>
        </v-flex>
      </v-layout>
    </div>
    <div v-if="tab === 1">
      <v-card class="mb-4">
        <v-toolbar flat color="grey lighten-5">
          <v-layout row wrap>
            <v-spacer></v-spacer>
            <v-flex xs4>
              <v-text-field
                v-model="search"
                append-icon="search"
                hide-details
                :label="$t('search')"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-toolbar>
        <div v-if="!transactions || !transactions[0]" class="pa-4">{{ $t("no-transactions-found") }}</div>
        <v-list class="pa-0" v-if="transactions && transactions[0]">
          <v-list-tile
            v-for="(transaction, i) in transactions"
            :key="'transaction-' + i"
            @click.stop
          >
            <v-list-tile-content>
              <v-list-tile-title>
                <v-layout row>
                  <v-flex xs4>
                    <div v-if="transaction.holder">
                      {{ transaction.holder.first_name }}
                      {{ transaction.holder.last_name }}
                    </div>
                    <div v-if="!transaction.holder" class="grey--text">{{ $t("user-is-deleted") }}</div>
                  </v-flex>
                  <v-flex xs4>
                    <div v-if="transaction.holder">{{ transaction.holder.email }}</div>
                  </v-flex>
                  <v-flex xs2 class="text-capitalize">{{ transaction.status }}</v-flex>
                  <v-flex xs2 class="text-xs-right">{{ transaction.amount / 100 }} {{ currency }}</v-flex>
                </v-layout>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
      <div v-if="totalPages > 1" class="text-xs-center">
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="totalVisible"
          @input="listGroupTransactions"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    DatePicker,
  },
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    tab: 0,
    transactions: [],
    transactionsWithRange: [],
    range: {},
    transactionsCount: null,
    currency: null,
    search: null,
    timeout: null,
    totalAmount: null,
    page: 1,
    perPage: 15,
    totalPages: null,
    totalVisible: 5,
  }),
  mounted() {
    this.listGroupTransactionsWithRange();
    this.listGroupTransactions();
  },
  methods: {
    listGroupTransactionsWithRange() {
      const params = [
        this.groupId,
        {
          start_date: this.formatDate(this.range.start_date),
          end_date: this.formatDate(this.range.end_date),
          per_page: 1000,
        },
      ];

      this.$api.groupTransactions.list(...params).then((res) => {
        this.transactionsWithRange = res.data.data;

        this.transactionsCount = res.data.pagination.total;
        this.totalAmount = res.data.meta.total / 100;
        this.currency = res.data.meta.currency;
      });
    },
    listGroupTransactions() {
      const params = [
        this.groupId,
        {
          search: this.search,
          page: this.page,
          per_page: this.perPage,
        },
      ];

      this.$api.groupTransactions.list(...params).then((res) => {
        this.transactions = res.data.data.map((el) => ({
          id: el.id,
          holder: el.transfer.from.holder,
          status: el.transfer.status,
          amount: el.amount,
        }));

        this.totalPages = res.data.pagination.total_pages;
      });
    },
    formatDate(date) {
      if (!date) return null;
      return moment.tz(date, this.userTimezone).format("YYYY-MM-DD");
    },
  },
  watch: {
    range: {
      deep: true,
      handler(val) {
        this.listGroupTransactionsWithRange();
      },
    },
    search() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.page = 1;
        this.listGroupTransactions();
      }, 800);
    },
  },
};
</script>
