<template>
  <v-container class="general">
    <PageTitle :component="'Transactions'" />
    <Transactions :group-id="groupId" />
  </v-container>
</template>

<script>
import Transactions from "@/components/Transactions/Transactions.vue";

export default {
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
  },
  components: {
    Transactions,
  },
};
</script>

<style scoped></style>
